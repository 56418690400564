import React from "react";
import styles from "./partnercard.module.scss";

const PartnerCard = ({ title, description, ...props }) => {
  return (
    <div className={styles.container} {...props}>
      <h3 className={styles.heading}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default PartnerCard;
