import React, { useEffect, useState } from "react";
import styles from "./Partners.module.scss";

import Partner1 from "../../../assets/video/VideoCallJulian_Comp.mp4";
import Partner2 from "../../../assets/video/TeamCall_Comp.mp4";
import arrowRight from "../../../assets/svg/angle_down_white.svg";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { LazyMotion, m, domAnimation } from "framer-motion";
import PartnerCard from "@atoms/PartnerCard";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";


const Partners = () => {
	const { t } = useTranslation();

	const breakpoints = useBreakpoint();
	const [digitalRef, digitalVisible] = useOnScreen({ threshold: 0.1 });
	const [anim, setAnim] = useState({ opacity: 0, y: 20 });
	const [animP, setAnimP] = useState({ opacity: 0, y: 20 });
	const [partner1ref, partner1Visible] = useOnScreen({ threshold: 0.3 });
	const [partner2ref, partner2Visible] = useOnScreen({ threshold: 0.3 });
	const [animPartner1, setAnimPartner1] = useState({
		opacity: 0,
		x: -20,
	});
	const [animPartner2, setAnimPartner2] = useState({ opacity: 0, x: 20 });

	useEffect(() => {
		if (digitalVisible) {
			setAnim({ opacity: 1, y: 0 });
			setAnimP({ opacity: 0.6, y: 0 });
		}
	}, [digitalVisible]);

	useEffect(() => {
		if (partner1Visible) {
			setAnimPartner1({ opacity: 1, x: 0 });
		}
	}, [partner1Visible]);

	useEffect(() => {
		if (partner2Visible) {
			setAnimPartner2({ opacity: 1, x: 0 });
		}
	}, [partner2Visible]);
	return (
		<section
			className={`container-fluid ${styles.section} ${styles.bgImgTest}`}
		>
			<div className="container">
				<div className="row">
					<div className="col-md-8 m-auto text-center mb106">
						<LazyMotion features={domAnimation}>

						<m.h2
							ref={digitalRef}							
							animate={anim}
							transition={{
								type: "spring",
								stiffness: 20,
							}}
							className={styles.section_title}
						>
							{t("PARTNERS.TITLE")}
						</m.h2>
						<m.p
							animate={animP}
							transition={{
								type: "spring",
								stiffness: 20,								
							}}
							className={styles.section_subtitle}
							>
							{t("PARTNERS.SUBTITLE")}
						</m.p>
						</LazyMotion>
					</div>
				</div>
				<LazyMotion features={domAnimation}>

				<m.div
					animate={animPartner1}
					ref={partner1ref}
					transition={{ duration: 0.3 }}
					className="text-center m-auto relative"
					style={{ marginTop: 130 }}
				>
					<PartnerCard
						title={t("PARTNERS.DIV1.TITLE")}
						description={t("PARTNERS.DIV1.SUBTITLE")}
						style={{
							width: 294,
							position: "absolute",
							top: breakpoints.sm ? -215 : -100,
							left: breakpoints.sm ? 0 : 60,
							zIndex: 1,
						}}
					/>
					<video
						className={`w-100 ${styles.img1}`}
						autoPlay
						loop
						muted
						playsInline
					>
						{ partner1Visible && <source src={Partner1} type="video/mp4" ></source> }
						Tu navegador no admite el elemento <code>video</code>.
					</video>

					{!breakpoints.sm && (
						<PartnerCard
							title={t("PARTNERS.DIV2.TITLE")}
							description={t("PARTNERS.DIV2.SUBTITLE")}
							style={{
								position: "absolute",
								bottom: 0,
								right: -80,
								zIndex: 1,
							}}
							/>
							)}
				</m.div>
				<m.div
					ref={partner2ref}
					animate={animPartner2}
					transition={{ duration: 0.1 }}
					style={{ marginTop: 180 }}
					className="text-center m-auto relative"
					>
					<video
						className={`w-100 ${styles.img2}`}
						autoPlay
						loop
						muted
						playsInline
						>
						{ partner2Visible && <source src={Partner2} type="video/mp4"></source>}
						Tu navegador no admite el elemento <code>video</code>.
					</video>
					<PartnerCard
						title={t("PARTNERS.DIV3.TITLE")}
						description={t("PARTNERS.DIV3.SUBTITLE")}
						style={{
							position: "absolute",
							bottom: breakpoints.sm ? -300 : 90,
							right: breakpoints.sm ? 0 : -75,
							zIndex: 1,
							width: 307,
						}}
						/>
				</m.div>
				</LazyMotion>
			</div>
			<div className="row my200">
				<div style={{ margin: "0 auto", width: "100%" }}>
					<Link
						to="/services"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: 100,
							width: "100%",
						}}
					>
						<p className={styles.allServices}>{t("PARTNERS.ALL_SERVICES")}</p>
						<img
							src={arrowRight}
							alt="Arrow"
							style={{ transform: "rotate(-90deg)", margin: 0, height: 8.5 }}
							loading="lazy" 
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Partners;
